.box {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
}
  
.part-1,
.part-2 {
    flex: 0 0 50%;
}
  
  

.centerContent {
    margin-left: auto;
    margin-right: auto;
}

.landingImg {
    position: relative;
    left: 150px;
    top: 100px;
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    -o-transform: scale(2);
    -webkit-transform: scale(2);
    transform: scale(2);
}

.landingImgSignature {
    position: relative;
    top: 180px;
    left: 200px;
}

@media screen and (max-width:1100px) {
    .box {
      flex-flow: column nowrap;
    }

    .landingImg {
        left: 0px;
        top: 0px;
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    .landingImgSignature {
        position: relative;
        top: 60px;
        left: 63px;
    }
}