.centerLoading {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center;
}

.centerSpinner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 15%;
}

.edit {
    width: 120px;
    max-width: 50%;
}

.pull-right {
    float:right;
}

.centerEditIcon{
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    top: 5px;
}

td {
    width: 100%;
}