.centerContent {
    text-align: center;
    align-items: center;
}

.borderCanvas {
    outline: black 1px solid;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 800px;
}

.buttonWidth {
    width: 150px;
}

.separateButton {
    margin-right: 25px;
}

.separateOptions {
    margin-right: 5px;
}