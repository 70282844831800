.wrapper {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
}

.accordion {
    width: auto;
}

.item {
    background-color: #f5f5f5;
    margin-bottom: 5px;
    padding: 10px 20px;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}

.content.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}