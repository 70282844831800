.cyber-signature-loader_req {
    position: relative;
    width: 200px;
    height: 200px; }
.cyber-signature-loader__sign_req {
    position: absolute;
    top: 60px;
    left: 60px;
    overflow: hidden;
    animation-name: cyberSignatureLoaderAnimation;
    animation-duration: 4s;
    animation-iteration-count: infinite; 
}
.cyber-signature-loader__documents_req {
    position: absolute;
    top: 0;
    left: 0; 
}

.landingImgSignature_req {
    position: relative;
    top: 180px;
    left: 200px;
}