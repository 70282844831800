.otp-cert{
    margin-left: auto;
    margin-right: auto;
    width: 8em
}

.center-otp-verification{
    margin-left: auto;
    margin-right: auto;
    width: 12em
}

.copyClipboard {
    cursor: pointer;
}