html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer; }

small, .small {
  font-size: 70%; }

sup {
  font-size: 50%;
  vertical-align: super; }

b, strong {
  font-weight: bold; }

* {
  box-sizing: border-box; }

html, body {
  width: 100%;
  overflow-x: hidden; }
  @media (min-width: 1024px) {
    html, body {
      overflow-x: visible; } }

html {
  scroll-behavior: smooth; }
  html.overflow-hidden {
    overflow: hidden;
    height: 100vh;
     }

body {
  line-height: 1;
  font-family: "IBM Plex Sans", sans-serif;
  color: #46474F;
  width: 100vw;
  overflow-x: hidden;
  font-size: 14px; }

a {
  color: #1972F2;
  text-decoration: none;
  cursor: pointer; }

hr {
  width: 100%;
  border: none;
  border-top: 1px solid #DBDBDF; }

h1, h2, h3, h4, h5, h6 {
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 1.2; }

h1, h2, h3 {
  font-weight: 300; }

h1 {
  font-size: 42px; }

h2 {
  font-size: 36px; }

h3 {
  font-size: 28px; }

h4 {
  font-size: 24px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 16px; }

@media (min-width: 768px) {
  h1 {
    font-size: 60px; }
  h2 {
    font-size: 48px; } }

@media (min-width: 1440px) {
  h1 {
    font-size: 72px; }
  h2 {
    font-size: 60px; } }

p, span, li, td {
  line-height: 1.5; }

p, ul, ol {
  margin-bottom: 20px; }

b {
  font-weight: 700; }

ul {
  list-style: disc;
  margin-left: 20px; }

.cyber-type__label {
  display: block;
  color: #91939D; }

.cyber-type__uppercase-label {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px; }

.cyber-type__h6 {
  font-size: 16px;
  display: block; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.center {
  margin: 0 auto; }

.flex {
  display: -ms-flexbox;
  display: flex; }

.direction-column {
  -ms-flex-direction: column;
      flex-direction: column; }

.justify-between {
  -ms-flex-pack: justify;
      justify-content: space-between; }

.align-start {
  -ms-flex-align: start;
      align-items: flex-start; }

.align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.justify-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center; }

.justify-end {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end; }

.hide {
  display: none; }

.visible {
  display: block; }

@media (min-width: 1024px) {
  .hide-on-large-up {
    display: none !important; } }

.show-on-large-up {
  display: none !important; }
  @media (min-width: 1024px) {
    .show-on-large-up {
      display: -ms-flexbox !important;
      display: flex !important; } }

.cyber-backdrop {
  position: fixed;
  inset: 0;
  background: rgba(29, 30, 32, 0.6);
  z-index: 80;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  cursor: pointer; }
  .cyber-backdrop.visible {
    opacity: 1;
    visibility: visible; }

.cyber-sidenav {
  background: #46474F;
  width: 256px;
  height: 100vh;
  padding: 16px;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 80;
  transform: translateX(-280px);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1); }
  .cyber-sidenav.visible {
    transform: translateX(0); }
  @media (min-width: 768px) {
    .cyber-sidenav {
      display: block;
      transform: translateX(0); } }
  .cyber-sidenav__logo {
    margin-bottom: 40px; }
  .cyber-sidenav__trigger {
    display: -ms-flexbox;
    display: flex; }
    @media (min-width: 768px) {
      .cyber-sidenav__trigger {
        display: none; } }

.cyber-nav__link {
  display: block;
  color: white;
  padding: 8px 16px;
  margin-bottom: 8px;
  border-radius: 4px;
  transition: all .3s; }
  .cyber-nav__link.active {
    background: rgba(255, 255, 255, 0.16); }
  .cyber-nav__link:hover {
    background: rgba(255, 255, 255, 0.08); }

.cyber-nav__divider {
  opacity: 0.2;
  margin: 16px; }

.cyber-header {
  position: fixed;
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid transparent;
  z-index: 10;
  display: -ms-flexbox;
  display: flex;
  transition: all .3s; }
  .cyber-header.scrolled {
    background: white;
    border-bottom: 1px solid #DBDBDF; }
  @media (min-width: 768px) {
    .cyber-header.with-sidenav {
      width: calc(100% - 256px);
      left: 256px; } }
  .cyber-header__column-left {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    width: 20%; }
  .cyber-header__column-center {
    width: 60%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center; }
  .cyber-header__column-right {
    width: 20%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: end;
        justify-content: flex-end; }
  .cyber-header__logo {
    display: block; }
    @media (min-width: 768px) {
      .cyber-header__logo {
        display: none; } }

.cyber-user-dropdown {
  position: relative; }
  .cyber-user-dropdown__trigger {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    color: #46474F;
    cursor: pointer; }
  .cyber-user-dropdown__user-info {
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: end;
        align-items: flex-end;
    display: none; }
    @media (min-width: 768px) {
      .cyber-user-dropdown__user-info {
        display: -ms-flexbox;
        display: flex; } }
  .cyber-user-dropdown__email {
    color: #91939D; }
  .cyber-user-dropdown__arrow {
    border: 1px solid #DBDBDF;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    margin-left: 8px;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto; }
  .cyber-user-dropdown__list {
    position: absolute;
    width: 100%;
    min-width: 280px;
    padding: 8px;
    background: white;
    right: 0;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    display: none; }
    .cyber-user-dropdown__list.visible {
      display: block; }
  .cyber-user-dropdown__item {
    padding: 8px;
    border-radius: 4px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    transition: all .3s; }
    .cyber-user-dropdown__item:hover {
      background: rgba(25, 114, 242, 0.08); }
  .cyber-user-dropdown__item-icon {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    background: rgba(22, 29, 45, 0.08);
    margin-right: 16px; }

.cyber-main {
  width: 100%;
  min-height: 100vh; }
  @media (min-width: 768px) {
    .cyber-main.with-sidenav {
      padding-left: 256px; } }
  .cyber-main.light-background {
    background: #F5F5F7; }
  .cyber-main.waves {
    background: #F5F5F7; }
    @media (min-width: 768px) {
      .cyber-main.waves {
        background: url("../src/images/bg_waves.svg") #F5F5F7 no-repeat top center/contain; } }

.cyber-footer {
  border-top: 1px solid #DBDBDF;
  padding: 24px 0;
  text-align: center; }
  .cyber-footer__copyright {
    margin-bottom: 24px; }
    @media (min-width: 768px) {
      .cyber-footer__copyright {
          margin-bottom: 0; } }
  @media (min-width: 768px) {
    .cyber-footer {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between; } }

.cyber-container {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 0 24px; }
  @media (min-width: 768px) {
    .cyber-container {
      padding: 0 80px; } }
  .cyber-container.max-width-600 {
    max-width: 600px; }
  .cyber-container.max-width-720 {
    max-width: 720px; }
  .cyber-container.max-width-800 {
    max-width: 800px; }
  .cyber-container.max-width-1000 {
    max-width: 1000px; }

.cyber-container-footer {
  position:relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 0 24px; }
  @media (min-width: 768px) {
    .cyber-container-footer {
      position:relative;
      margin: 0 auto;
      left: 120px;
      padding: 0 80px; 
    } }
  .cyber-container-footer.max-width-600 {
    max-width: 600px; }
  .cyber-container-footer.max-width-720 {
    max-width: 720px; }
  .cyber-container-footer.max-width-800 {
    max-width: 800px; }

.cyber-spacer {
  width: 100%;
  height: 8px; }
  .cyber-spacer.small {
    height: 24px; }
  .cyber-spacer.medium {
    height: 40px; }
  .cyber-spacer.large {
    height: 80px; }
  .cyber-spacer.section {
    height: 120px; }

.cyber-flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.cyber-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  gap: 16px;
  -ms-flex-align: stretch;
      align-items: stretch; }
  @media (min-width: 1280px) {
    .cyber-grid.two-columns {
      -ms-grid-columns: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
      gap: 32px; } }
  @media (min-width: 1280px) {
    .cyber-grid.three-columns {
      -ms-grid-columns: 1fr 1fr 1fr;
          grid-template-columns: 1fr 1fr 1fr;
      gap: 32px; } }
  @media (min-width: 1280px) {
    .cyber-grid.four-columns {
      -ms-grid-columns: 1fr 1fr 1fr 1fr;
          grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 32px; } }

.cyber-block.col1 {
  width: 8.3333%; }

.cyber-block.col2 {
  width: 16.6666%; }

.cyber-block.col3 {
  width: 25%; }

.cyber-block.col4 {
  width: 33.3333%; }

.cyber-block.col5 {
  width: 41.6666%; }

.cyber-block.col6 {
  width: 50%; }

.cyber-block.col7 {
  width: 58.3333%; }

.cyber-block.col8 {
  width: 66.6666%; }

.cyber-block.col10 {
  width: 75%; }

.cyber-block.col11 {
  width: 75%; }

.cyber-block.col12 {
  width: 100%; }

.cyber-preloader {
  animation-name: cyberPreloaderAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite; }

@keyframes cyberPreloaderAnimation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.cyber-alert {
  background: #F5F5F7;
  padding: 24px;
  border-radius: 8px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
      align-items: stretch;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @media (min-width: 1024px) {
    .cyber-alert {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; } }
  .cyber-alert__close {
    position: absolute;
    top: 8px;
    right: 8px; }
  .cyber-alert__title {
    color: #1972F2;
    margin-bottom: 8px; }
  .cyber-alert__image-container {
    width: 60px;
    height: 60px;
    margin-right: 24px;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center; }
    @media (min-width: 768px) {
      .cyber-alert__image-container {
        width: 80px;
        height: 80px; } }
    .cyber-alert__image-container img {
      width: 100%;
      display: block; }
    .cyber-alert__image-container .material-icons {
      width: 60px;
      height: 60px;
      border: 1px solid #BFC0C7;
      font-size: 48px;
      display: -ms-grid;
      display: grid;
      place-items: center;
      border-radius: 50%; }
  .cyber-alert__content {
    max-width: 600px;
    width: calc(100% - 84px); }
    @media (min-width: 768px) {
      .cyber-alert__content {
        width: calc(100% - 104px); } }
    @media (min-width: 1024px) {
      .cyber-alert__content {
        width: 100%; } }
  .cyber-alert__actions {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
        align-items: flex-end;
    -ms-flex-pack: end;
        justify-content: flex-end;
    width: calc(100% - 84px);
    margin-left: auto;
    margin-top: 24px; }
    @media (min-width: 768px) {
      .cyber-alert__actions {
        width: calc(100% - 104px); } }
    @media (min-width: 1024px) {
      .cyber-alert__actions {
        margin-left: 24px;
        margin-top: 0; } }
  .cyber-alert.success {
    background: rgba(99, 184, 194, 0.24); }
    .cyber-alert.success .cyber-alert__title {
      color: #63B8C2; }
    .cyber-alert.success .cyber-alert__image-container .material-icons {
      color: #63B8C2;
      border: 1px solid #63B8C2; }

.cyber-button {
  position: relative;
  color: white;
  padding: 8px 24px;
  border: 1px solid #1972F2;
  border-radius: 4px;
  font-family: "IBM Plex Sans", sans-serif;
  background: #1972F2;
  font-weight: bold;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: all, .3s; }
  .cyber-button:hover {
    border: 1px solid #0054D4;
    background: #2D86FF; }
  .cyber-button.bordered {
    color: #1972F2;
    background: transparent; }
    .cyber-button.bordered:hover {
      background: #1972F2;
      color: white; }
  .cyber-button.link {
    border: none;
    background: none;
    color: #1972F2; }
  .cyber-button.full {
    width: 100%; }
  .cyber-button.hide-label-on-mobile .material-icons {
    margin-right: 0; }
    @media (min-width: 1024px) {
      .cyber-button.hide-label-on-mobile .material-icons {
        margin-right: 8px; } }
  .cyber-button.hide-label-on-mobile .cyber-button__label {
    display: none; }
    @media (min-width: 1024px) {
      .cyber-button.hide-label-on-mobile .cyber-button__label {
        display: inline-block; } }
  .cyber-button .material-icons {
    margin-right: 8px; }
  .cyber-button.icon-right {
    padding-right: 12px; }
    .cyber-button.icon-right .material-icons {
      margin: 0;
      margin-left: 8px; }

.cyber-fab {
  border: 1px solid #DBDBDF;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  transition: all, .3s;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto; }
  .cyber-fab:hover {
    background: #1972F2;
    color: white;
    border: 1px solid #0054D4;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.08); }
  .cyber-fab.table-action {
    position: absolute;
    bottom: 0;
    right: 0; }
    @media (min-width: 1024px) {
      .cyber-fab.table-action {
        position: initial; } }

.cyber-placeholder {
  max-width: 420px;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }
  .cyber-placeholder__image {
    margin-bottom: 40px; }
  .cyber-placeholder__title {
    margin-bottom: 24px;
    text-align: center; }

.cyber-tabs {
  position: relative; }
  .cyber-tabs__nav {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow: auto;
    padding-bottom: 8px; }
  .cyber-tabs__link {
    display: block;
    padding: 16px 24px;
    white-space: nowrap;
    border-radius: 8px; }
    .cyber-tabs__link.active {
      background: white; }
  @media (min-width: 1024px) {
    .cyber-tabs.vertical {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .cyber-tabs.vertical .cyber-tabs__nav {
        max-width: 280px;
        -ms-flex-direction: column;
            flex-direction: column;
        padding-right: 24px;
        position: sticky;
        top: 80px; }
      .cyber-tabs.vertical .cyber-tabs__link {
        white-space: initial;
        line-height: 1.2; }
      .cyber-tabs.vertical .cyber-tabs__panels {
        width: calc(100% - 280px); } }

.cyber-profile {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .cyber-profile__title {
    display: none; }
    @media (min-width: 1024px) {
      .cyber-profile__title {
        display: block; } }
  .cyber-profile__sidebar-mobile {
    width: 100%; }
    @media (min-width: 1024px) {
      .cyber-profile__sidebar-mobile {
        width: 280px;
        display: block;
        padding-right: 24px; }
        }
  .cyber-profile__sidebar {
    display: none; }
    @media (min-width: 1024px) {
      .cyber-profile__sidebar {
        width: 280px;
        display: block;
        padding-right: 24px; } }
  .cyber-profile__nav {
    background: white;
    border-radius: 8px;
    padding: 16px 0; }
    @media (min-width: 1024px) {
      .cyber-profile__nav {
        padding: 0;
        background: transparent;
        position: sticky;
        top: 80px; } }
  .cyber-profile__nav-link {
    display: block;
    padding: 16px; }
    @media (min-width: 1024px) {
      .cyber-profile__nav-link {
        padding: 16px 24px;
        border-radius: 8px;
        line-height: 1.5; }
        .cyber-profile__nav-link.active {
          background: white; } }
  .cyber-profile__content {
    width: 100%; }
    @media (min-width: 1024px) {
      .cyber-profile__content {
        width: calc(100% - 280px); } }
  .cyber-profile__back {
    display: block;
    margin-bottom: 16px; }
    @media (min-width: 1024px) {
      .cyber-profile__back {
        display: none; } }

.cyber-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.08);
  padding: 24px;
  transition: all .3s; }
  .cyber-card.center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center; }
  .cyber-card.bordered {
    border: 1px solid #DBDBDF;
    box-shadow: none; }
    .cyber-card.bordered:hover {
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.08); }
  .cyber-card.default {
    background: #F5F5F7;
    box-shadow: none; }

.cyber-steps {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 100%; }
  .cyber-steps__item {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    padding: 0 12px;
    width: 100%; }
    .cyber-steps__item:before, .cyber-steps__item:after {
      content: '';
      position: absolute;
      width: 50%;
      height: 4px;
      top: 18px;
      background: #DBDBDF; }
    .cyber-steps__item:after {
      left: 50%;
      z-index: -1; }
    .cyber-steps__item:before {
      left: 0;
      z-index: -1; }
    .cyber-steps__item:first-child:before {
      display: none; }
    .cyber-steps__item:last-child:after {
      display: none; }
  .cyber-steps__item-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    background: #DBDBDF;
    margin-bottom: 4px; }
    @media (min-width: 768px) {
      .cyber-steps__item-icon {
        width: 40px;
        height: 40px; } }
    .cyber-steps__item-icon span {
      color: #91939D;
      font-size: 18px; }
      @media (min-width: 768px) {
        .cyber-steps__item-icon span {
          font-size: 24px; } }
  .cyber-steps__item-label {
    font-size: 12px;
    color: #46474F;
    line-height: 1;
    text-align: center;
    display: none; }
    @media (min-width: 768px) {
      .cyber-steps__item-label {
        display: block; } }
  .cyber-steps .cyber-steps__item.active .cyber-steps__item-icon {
    background: #1972F2; }
    .cyber-steps .cyber-steps__item.active .cyber-steps__item-icon span {
      color: white; }
  .cyber-steps .cyber-steps__item.active .cyber-steps__item-label {
    color: #1972F2; }
  .cyber-steps .cyber-steps__item.active:before {
    background: #63B8C2; }
  .cyber-steps .cyber-steps__item.done .cyber-steps__item-icon {
    background: #63B8C2; }
    .cyber-steps .cyber-steps__item.done .cyber-steps__item-icon span {
      color: white; }
  .cyber-steps .cyber-steps__item.done .cyber-steps__item-label {
    color: #63B8C2; }
  .cyber-steps .cyber-steps__item.done:before {
    background: #63B8C2; }
  .cyber-steps .cyber-steps__item.done:after {
    background: #63B8C2; }

.cyber-menu {
  color: #46474F;
  font-weight: 400;
  position: absolute;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.08);
  padding: 24px;
  z-index: 10;
  right: -8px;
  bottom: 48px;
  min-width: 240px;
  text-align: left;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.001) rotate(-45deg);
  transform-origin: bottom right; }
  .cyber-menu.visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1); }
  .cyber-menu__item {
    display: block;
    padding: 8px 0;
    font-weight: bold; }

.cyber-form {
  width: 100%; }
  .cyber-form .cyber-input {
    margin-bottom: 24px; }

.cyber-input {
  position: relative;
  width: 100%; }
  .cyber-input__label {
    display: block;
    margin-bottom: 8px; }
  .cyber-input__textfield {
    display: block;
    border: none;
    border: 1px solid #DBDBDF;
    width: 100%;
    padding: 8px 16px;
    border-radius: 4px; }
  .cyber-input__disabled {
    background-color: rgba(59, 59, 59, 0.2);
  }
  .cyber-input__icon-right {
    position: absolute;
    right: 8px;
    bottom: 4px; }
  .cyber-input__icon-left {
    position: absolute;
    left: 8px;
    bottom: 4px; }
  .cyber-input.icon-left .cyber-input__textfield {
    padding-left: 40px; }

.cyber-checkbox {
  display: -ms-flexbox;
  display: flex;
  /* On mouse-over, add a grey background color */ }
  .cyber-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .cyber-checkbox__container {
    width: 100%; }
  .cyber-checkbox__button {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    height: 80px;
    border: 1px solid #DBDBDF;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    padding: 24px; }
  .cyber-checkbox .cyber-checkbox__container:hover input ~ .cyber-checkbox__button {
    background-color: #F5F5F7; }
  .cyber-checkbox .cyber-checkbox__container input:checked ~ .cyber-checkbox__button {
    background-color: #1972F2;
    border: 1px solid #0054D4;
    color: white; }

.cyber-table__th {
  display: none;
  font-weight: bold;
  color: #91939D;
  border-bottom: 2px solid #91939D; }
  @media (min-width: 1024px) {
    .cyber-table__th {
      display: -ms-flexbox;
      display: flex; } }

.cyber-table__tr {
  border-bottom: 1px solid #DBDBDF;
  display: block;
  padding: 16px 0;
  position: relative; }
  @media (min-width: 1024px) {
    .cyber-table__tr {
      display: -ms-flexbox;
      display: flex;
      padding: 0; } }
  .cyber-table__tr.with-fab {
    padding-top: 32px; }
    @media (min-width: 1024px) {
      .cyber-table__tr.with-fab {
        padding-top: 0; } }

.cyber-table__td {
  position: relative;
  padding: 8px 0; }
  @media (min-width: 1024px) {
    .cyber-table__td {
      padding: 16px 0; } }
  @media (min-width: 1024px) {
    .cyber-table__td.col1 {
      width: 8.3333%; }
    .cyber-table__td.col2 {
      width: 16.6666%; }
    .cyber-table__td.col3 {
      width: 25%; }
    .cyber-table__td.col4 {
      width: 33.3333%; }
    .cyber-table__td.col5 {
      width: 41.6666%; }
    .cyber-table__td.col6 {
      width: 50%; }
    .cyber-table__td.col7 {
      width: 58.3333%; }
    .cyber-table__td.col8 {
      width: 66.6666%; }
    .cyber-table__td.col10 {
      width: 75%; }
    .cyber-table__td.col11 {
      width: 75%; }
    .cyber-table__td.col12 {
      width: 100%; } }

.cyber-badge {
  text-transform: uppercase;
  padding: 4px 8px;
  font-size: 12px;
  background: #F5F5F7;
  border-radius: 4px; }

.cyber-modal {
  position: fixed;
  inset: 0;
  display: -ms-grid;
  display: grid;
  place-items: center;
  overflow: auto;
  padding: 24px;
  z-index: 100;
  transform: translateY(100vh);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1); }
  .cyber-modal__container {
    position: relative;
    background: white;
    width: 100%;
    max-width: 600px;
    padding: 24px;
    border-radius: 8px; }
  .cyber-modal__close {
    position: absolute;
    top: 8px;
    right: 8px; }
  .cyber-modal__step {
    display: none; }
    .cyber-modal__step.visible {
      display: block; }
  .cyber-modal.visible {
    transform: translateY(0); }

.cyber-list__item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 8px 0; }
  .cyber-list__item.success {
    color: #71C78E; }
    .cyber-list__item.success .cyber-list__item-icon-left {
      border: 1px solid #71C78E; }
  .cyber-list__item.error {
    color: #FF5A6E; }
    .cyber-list__item.error .cyber-list__item-icon-left {
      border: 1px solid #FF5A6E; }

.cyber-list__item-icon-left {
  border: 1px solid #1972F2;
  border-radius: 50%;
  font-size: 24px;
  width: 40px;
  height: 40px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  margin-right: 16px;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto; }

.cyber-list__item-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto; }

.cyber-list__item-icon-right {
  color: #BFC0C7; }

.cyber-register-list {
  border-top: 1px solid #DBDBDF;
  padding-top: 24px; }
  .cyber-register-list__item {
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #DBDBDF;
    padding-bottom: 24px;
    margin-bottom: 24px; }
  .cyber-register-list__item-number {
    color: #1972F2;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    border: 1px solid #DBDBDF;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    margin-right: 24px; }

.cyber-2fa-badges {
  margin-bottom: 24px;
  display: -ms-flexbox;
  display: flex; }
  .cyber-2fa-badges__ios {
    display: block;
    margin-right: 12px; }

.cyber-input-signature {
  border: 1px solid #DBDBDF;
  border-radius: 4px;
  padding: 8px; }
  .cyber-input-signature__area {
    height: 120px;
    display: -ms-grid;
    display: grid;
    place-items: center; }

.cyber-document-container {
  background: #F5F5F7;
  padding: 24px;
  display: -ms-grid;
  display: grid;
  place-items: center; }

.cyber-upload-box {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 40px;
  border: 1px dashed #DBDBDF;
  border-radius: 16px; }
  .cyber-upload-box__icon {
    width: 100px; }
  .cyber-upload-box__form {
    display: -ms-flexbox;
    display: flex; }
  .cyber-upload-box__input {
    background: #F5F5F7;
    padding: 8px;
    margin-right: 8px;
    border-radius: 4px; }
    .cyber-upload-box__input input {
      cursor: pointer; }

.cyber-file-loader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  width: 100%; }
  .cyber-file-loader__content {
    margin-right: 24px;
    width: 100%; }
  .cyber-file-loader__bar {
    height: 8px;
    border: 1px solid #DBDBDF;
    border-radius: 4px;
    padding: 2px;
    margin-bottom: 8px;
    margin-top: 30px; }
  .cyber-file-loader__progress {
    background: #1972F2;
    width: 75%;
    height: 2px; }

.cyber-document-to-sign {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .cyber-document-to-sign__info {
    margin-right: 16px;
    display: block;
    width: calc(100% - 50px); }
  .cyber-document-to-sign__filename {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }

.cyber-signature-options {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  gap: 32px;
  -ms-flex-align: stretch;
      align-items: stretch; }
  @media (min-width: 768px) {
    .cyber-signature-options {
      -ms-grid-columns: 1fr 1fr;
          grid-template-columns: 1fr 1fr; } }
  .cyber-signature-options__paper {
    background: white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.08);
    height: 320px;
    position: relative;
    border: 16px solid white;
    border-radius: 4px; }
  .cyber-signature-options__position {
    position: absolute; }
    .cyber-signature-options__position.top-left {
      top: 0;
      left: 0; }
    .cyber-signature-options__position.top-center {
      top: 0;
      left: calc(50% - 4px); }
    .cyber-signature-options__position.top-right {
      top: 0;
      right: 0; }
    .cyber-signature-options__position.middle-left {
      top: calc(50% - 4px);
      left: 0; }
    .cyber-signature-options__position.middle-center {
      top: calc(50% - 4px);
      left: calc(50% - 4px); }
    .cyber-signature-options__position.middle-right {
      top: calc(50% - 4px);
      right: 0; }
    .cyber-signature-options__position.bottom-left {
      bottom: 0;
      left: 0; }
    .cyber-signature-options__position.bottom-center {
      bottom: 0;
      left: calc(50% - 4px); }
    .cyber-signature-options__position.bottom-right {
      bottom: 0;
      right: 0; }

.cyber-signature-loader {
  position: relative;
  width: 200px;
  height: 200px; }
  .cyber-signature-loader__sign {
    position: absolute;
    top: 60px;
    left: 60px;
    overflow: hidden;
    animation-name: cyberSignatureLoaderAnimation;
    animation-duration: 4s;
    animation-iteration-count: infinite; }
  .cyber-signature-loader__documents {
    position: absolute;
    top: 0;
    left: 0; }

@keyframes cyberSignatureLoaderAnimation {
  0% {
    width: 0; }
  20% {
    width: 150px; }
  80% {
    width: 150px; }
  100% {
    width: 0; } }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.PDFDocument {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 620px;
  overflow-y: auto;
}
  
.PDFPage {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
  
.PDFPageOne {
  margin-bottom: 25px;
}
  
.PDFPage > canvas {
  max-width: 475px;
  height: auto !important;
}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__annotations {
  display: none;
}
