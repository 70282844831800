.icon-button {
    position: relative;
    left: -4px;
}

.icon-buttonDown {
    position: relative;
    top: 3px;
}

.moveRight {
    float:right;
}

.moveDown {
    position: relative;
    top: 4px;
}

.modalContainer {
    position: relative;
    background: white;
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
}

.pNoMarginB {
    margin-bottom: 0px;
}

.selectMB{
    margin-bottom: 10px;
}

.alignButton{
    margin-right: 5px;
}

.alignButtonMobile{
    margin-right: 5px;
}

.alignSpinner {
    position: relative;
    left: 40px;
}

.alignWaitingStep {
        margin: auto;
        width: 55%;
}

.myDiv
{
  height: 120px;
  width: 313.75px;
}
.myDiv img
{
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}

@media (max-width: 1024px) {

    .alignButton{
        margin-right: 5px;
        margin-bottom: 20px;
    }

    .alignButtonMobile{
        margin-right: 5px;
        margin-bottom: 62px;
    }

    .alignSpinner {
        position: relative;
        left: 30px;
    }
}