.cyber-input__textfield_contact {
    display: block;
    border: none;
    border: 1px solid #DBDBDF;
    width: 100%;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px; 
    font-family: "IBM Plex Sans", sans-serif;
}

.inputLayout_contact {
    position: relative;
    font-size: 14px; 
    font-family: "IBM Plex Sans", sans-serif;
}