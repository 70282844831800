.inputLayout {
    position: relative;
    margin: 10px;
}

.certSerial{
    display:inline-block;
    margin-right:10px; 
    width:235px; 
} 

.caHash{
    display:inline-block; 
    width:235px;
} 